<template>
  <div>
    <el-dialog
      v-model="dialog.show"
      width="400"
      draggable
      top="auto"
      :show-close="false"
      align-center
      :before-close="closeDialog"
    >
      <template #header="{ close, titleId, titleClass }">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            height: 60px;
          "
        >
          <headerCommon
            :title="dialog.title"
            :titleShow="true"
            style="margin-top: 5px"
          />
          <div>
            <img
              v-if="tenantLogo"
              style="width: 32px; height: 32px"
              :src="tenantLogo"
            />
          </div>
        </div>
      </template>

      <div style="background-color: #d8d8d8; width: 100%; height: 1px"></div>
      <el-form
        ref="ruleFormRef"
        :model="formData"
        :label-width="labelWidth ? labelWidth : 'auto'"
        label-position="right"
        scroll-to-error
      >
        <FormItem
          :formTitles="formTitles"
          :grid="1"
          :formData="formData"
          @handleDetailsBtn="handleDetailsBtn"
          @selectChange="selectChange"
          @handelSearch="handelBlur"
          @handelRadioChange="handelRadioChange"
          @filterMethod="filterMethod"
          @handleNodeClick="handleNodeClick"
          @handelTreeChange="handelTreeChange"
          @cascaderSelectChange="cascaderSelectChange"
        >
          <template
            v-for="(field, index) in formTitles.filter(
              (v) => v.fontendType === 'slot'
            )"
            :key="index"
            #[field.fieldName]
          >
            <slot :name="field.fieldName"></slot>
          </template>
        </FormItem>
      </el-form>
      <template #footer>
        <div class="dialog_footer">
          <el-button
            v-permission="
              dialog.cancelBtnCode ? dialog.cancelBtnCode : 'default'
            "
            v-if="dialog.cancelBtnText"
            @click="handelCancel(ruleFormRef)"
            :style="{
              color: dialog.cancelBtnColor ? dialog.cancelBtnColor : '#303133',
            }"
            >{{ dialog.cancelBtnText }}</el-button
          >
          <el-button @click="closeDialog(ruleFormRef)">取消</el-button>
          <el-button
            v-permission="
              dialog.confirmBtnCode ? dialog.confirmBtnCode : 'default'
            "
            type="primary"
            @click="confirm(ruleFormRef)"
            v-if="!dialog.isShowBtn"
          >
            {{ dialog.handleBtnText }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import headerCommon from "@/components/header.vue";
import { ref, onMounted, defineEmits, defineProps } from "vue";

const emits = defineEmits([
  "submitDialog",
  "closeDialog",
  "handelBlur",
  "handelSubmit",
  "handleDetailsBtn",
  "selectChange",
  "handelRadioChange",
  "handelTreeChange",
  "uploadOcr",
  "selectTabelRowClick",
  "filterChange",
  "cascaderChange",
  "editTabelBtnClick",
  "selectTabelCurrentChange",
  "handleFocus",
  "filterMethod",
  "handleNodeClick",
  "cascaderSelectChange",
]);

const { dialog, reviewData, formTitles, grid, labelWidth } = defineProps({
  dialog: Object,
  labelWidth: String,
  reviewData: Object,
  formTitles: Array,
  grid: Number,
});
const tenantLogo = ref(""); // 租户logo
const formData = ref({}); // 表单数据
const ruleFormRef = ref(null); // 表单ref

// formData.value = reviewData ? reviewData : {};
onMounted(() => {
  tenantLogo.value = JSON.parse(localStorage.getItem("user")).tenantLogo;
  dialog.handleBtnText = dialog.handleBtnText ? dialog.handleBtnText : "提交";
});

// 提交
const confirm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      emits("submitDialog", formData.value, dialog.handleBtnText);
    } else {
      console.log(valid, fields);
    }
  });
};

const handleNodeClick = (parent, node) => {
  emits("handleNodeClick", parent, node);
};

const cascaderSelectChange = (value, name) => {
  emits("cascaderSelectChange", value, name, formData.value);
};

const filterMethod = (value, name, index) => {
  emits("filterMethod", value, name, index);
};

const handelTreeChange = (val, treeRef, formData) => {
  emits("handelTreeChange", val, treeRef, formData);
};

const handelRadioChange = (val, label) => {
  emits("handelRadioChange", val, label);
};

const handelBlur = (form, name) => {
  name === "idCardNo" ? emits("handelBlur", form) : "";
};

const selectChange = (val, item, data) => {
  emits("selectChange", val, item, "model", data);
};

const handleDetailsBtn = (name) => {
  emits("handleDetailsBtn", name, formData.value);
};

const handelCancel = () => {
  emits("handelSubmit");
};

// 关闭抽屉
const closeDialog = () => {
  formData.value = {};
  emits("closeDialog");
};
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
  padding: 0;
  margin-top: 5%;
  // position: fixed;
  // top: 50%;
  // right: 50%;
  // transform: translate(-50%, -50%);

  .el-dialog__header,
  .el-dialog__footer {
    padding: 0;
  }

  .el-dialog__body {
    max-height: calc(100vh - 62px);
  }
}

:deep(.el-overlay-dialog) {
  overflow: hidden;
}

.dialog_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  height: 62px;
  padding: 20px;
  border-top: 1px solid #d8d8d8;
}
</style>
