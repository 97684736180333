<template>
  <div class="gloabal">
    <el-drawer
      :size="drawer.size"
      v-model="drawer.show"
      :with-header="false"
      :show-close="true"
      :close-on-click-modal="true"
      :close-on-press-escape="false"
      @close="closeDrawer"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          height: 60px;
        "
      >
        <headerCommon
          :title="drawer.title"
          :titleShow="true"
          style="margin-top: 5px"
        />
        <div>
          <img
            v-if="tenantLogo"
            style="width: 32px; height: 32px; border-radius: 50%"
            :src="tenantLogo"
          />
        </div>
      </div>

      <div style="background-color: #d8d8d8; width: 100%; height: 1px"></div>
      <div v-if="drawer.sysCode" class="number">
        <span style="margin-right: 10px"
          >{{ drawer.sysCode.sysTitle }}:
          <span>&nbsp; {{ drawer.sysCode.sysValue }}/系统生成</span></span
        >
        <el-tag :type="drawer.sysCode.type">{{
          drawer.sysCode.sysStatus
        }}</el-tag>
        <span v-if="drawer.sysCode.isRemark"
          >{{ drawer.sysCode.sysRemarkTitle }}:
          <span>&nbsp; {{ drawer.sysCode.sysRemark }}</span></span
        >
        <el-tooltip
          v-if="drawer.sysCode.tooltip"
          :content="drawer.sysCode.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
      </div>
      <el-form
        ref="ruleFormRef"
        :model="formData"
        :label-width="labelWidth ? labelWidth : 'auto'"
        label-position="right"
        scroll-to-error
      >
        <FormItem
          :formTitles="formTitles"
          :formData="formData"
          :grid="grid"
          :formHeight="drawer.formHeight"
          @handleDetailsBtn="handleDetailsBtn"
          @selectChange="selectChange"
          @handleAdd="handleAdd"
          @handelSearch="handelBlur"
          @handelBlur="handelBlur"
          @handelRadioChange="handelRadioChange"
          @filterMethod="filterMethod"
          @handleNodeClick="handleNodeClick"
          @handelTreeChange="handelTreeChange"
          @cascaderSelectChange="cascaderSelectChange"
        >
          <template
            v-for="(field, index) in formTitles.filter(
              (v) => v.fontendType === 'slot'
            )"
            :key="index"
            #[field.fieldName]
          >
            <slot :name="field.fieldName"></slot>
          </template>
        </FormItem>
      </el-form>

      <EditTabel
        v-if="editTabelData.tabelList"
        :editTabelData="editTabelData"
        :formData="formData"
        @selectTabelRowClick="selectTabelRowClick"
        @uploadOcr="uploadOcr"
        @handleFocus="handleFocus"
        @handleRecommend="handleRecommend"
        @filterChange="filterChange"
        @cascaderChange="cascaderChange"
        @selectTabelAdd="handleAdd"
        @editTabelBtnClick="editTabelBtnClick"
        @selectTabelCurrentChange="selectTabelCurrentChange"
      />

      <slot name="otherContent" v-bind="{ formData }"></slot>

      <template #footer>
        <div class="drawer_footer">
          <el-button
            v-permission="
              drawer.cancelBtnCode ? drawer.cancelBtnCode : 'default'
            "
            v-if="drawer.cancelBtnText"
            @click="handelCancel(ruleFormRef)"
            :style="{
              color: drawer.cancelBtnColor ? drawer.cancelBtnColor : '#303133',
            }"
            >{{ drawer.cancelBtnText }}</el-button
          >
          <el-button @click="closeDrawer(ruleFormRef)">取消</el-button>
          <el-button
            v-permission="
              drawer.confirmBtnCode ? drawer.confirmBtnCode : 'default'
            "
            type="primary"
            @click="confirm(ruleFormRef)"
            v-if="!drawer.isShowBtn"
          >
            {{ drawer.handleBtnText }}
          </el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>
<script setup>
import { Warning } from "@element-plus/icons-vue";
import headerCommon from "@/components/header.vue";
import { ref, onMounted, defineEmits, defineProps, handleError } from "vue";
import { ElMessage } from "element-plus";

const emits = defineEmits([
  "submitForm",
  "closeModel",
  "handelBlur",
  "handelSubmit",
  "handleDetailsBtn",
  "selectChange",
  "handelRadioChange",
  "handelTreeChange",
  "uploadOcr",
  "selectTabelRowClick",
  "filterChange",
  "cascaderChange",
  "editTabelBtnClick",
  "selectTabelCurrentChange",
  "handleFocus",
  "filterMethod",
  "handleNodeClick",
  "cascaderSelectChange",
  "handleAdd",
  "handleRecommend",
]);
const {
  drawer,
  showFieldOptionsDialog,
  reviewData,
  formTitles,
  grid,
  labelWidth,
  editTabelData,
} = defineProps({
  drawer: Object, // 抽屉数据
  labelWidth: String, // 表单label宽度
  reviewData: Object, // 回显数据
  formTitles: Array, // 表单标题列表
  grid: Number, // 表单列数
  editTabelData: {
    type: Object,
    default: {
      tabelHeaders: [],
    },
  }, // 编辑表格数据
});
const tenantLogo = ref(""); // 租户logo
const formData = ref({}); // 表单数据
const ruleFormRef = ref(null); // 表单ref

formData.value = reviewData ? reviewData : {};
console.log("formData", formData.value);

onMounted(() => {
  tenantLogo.value = JSON.parse(localStorage.getItem("user")).tenantLogo;
  drawer.handleBtnText = drawer.handleBtnText ? drawer.handleBtnText : "提交";
});

// 提交
const confirm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      emits("submitForm", formData.value, drawer.handleBtnText);
    } else {
      // ElMessage({
      //   type: "warning",
      //   message: "请填写正确的参数",
      // });
    }
  });
};

const handleRecommend = () => {
  emits("handleRecommend");
};

const handleAdd = (name) => {
  emits("handleAdd", name);
};

const handleNodeClick = (parent, node) => {
  emits("handleNodeClick", parent, node);
};

const cascaderSelectChange = (value, name) => {
  emits("cascaderSelectChange", value, name);
};

const filterMethod = (value, name, index) => {
  emits("filterMethod", value, name, index);
};

const handleFocus = () => {
  emits("handleFocus");
};

const selectTabelCurrentChange = (current, item) => {
  emits("selectTabelCurrentChange", current, item);
};

const editTabelBtnClick = (row) => {
  emits("editTabelBtnClick", row);
};

const cascaderChange = (text, index) => {
  emits("cascaderChange", text, index);
};

const filterChange = (val, prop, index) => {
  emits("filterChange", val, prop, index);
};

const selectTabelRowClick = (row, index) => {
  emits("selectTabelRowClick", row, index);
};

const uploadOcr = (url, index) => {
  emits("uploadOcr", url, index);
};

const handelTreeChange = (val, treeRef, formData) => {
  emits("handelTreeChange", val, treeRef, formData);
};

const handelRadioChange = (val, label) => {
  emits("handelRadioChange", val, label);
};

const handelBlur = (form, name) => {
  name === "idCardNo" ? emits("handelBlur", formData.value) : "";
};

const selectChange = (val, item, data) => {
  emits("selectChange", val, item, "model", data);
};

const handleDetailsBtn = (name) => {
  emits("handleDetailsBtn", name, formData.value);
};

const handelCancel = () => {
  emits("handelSubmit");
};

// 关闭抽屉
const closeDrawer = () => {
  formData.value = {};
  emits("closeModel");
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.drawer_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  height: 62px;
  padding: 20px;
  border-top: 1px solid #d8d8d8;
}

:deep(.el-drawer .el-drawer__footer) {
  padding: 0px !important;
}
.number {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  border-bottom: #d8d8d8 1px solid;
  line-height: 49px;
  color: #999999;
  font-size: 12px;
  padding-left: 21px;
  padding-right: 20px;
}

:deep(.el-button) {
  font-size: 12px;
}
</style>
